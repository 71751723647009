<template>
  <div class="owners-list-table">
    <div class="table-number-sort">
      <div class="table-number" data-e2e="table-number">
        {{ listTableBodyData.length }}件
      </div>
      <div v-if="sortTypes.length >= 1" class="table-sort">
        <p>並び替え</p>
        <base-select-box
          class="sort-box"
          :styles="sortBoxStyle"
          :selectData="sortTypeSelectData"
          :value="sortType"
          @input="changeSortType"
        />
        <base-select-box
          class="order-box"
          :styles="orderBoxStyle"
          :selectData="orderTypesSelectData"
          :value="orderType"
          @input="changeOrderType"
        />
      </div>
    </div>
    <div class="table-header">
      <owners-list-table-header-row
        :bulkSendMailModeFlg="bulkSendMailModeFlg"
        :downloadCsvModeFlg="downloadCsvModeFlg"
        :bulkCheckFlg="bulkCheckFlg"
        v-bind="listTableHeaderData"
        @bulk-check-target-owner-flg="bulkCheckTargetOwnerFlg"
      />
    </div>
    <dynamic-scroller
      v-if="listTableBodyData.length > 0"
      class="table-body"
      :key="searchCount"
      :items="listTableBodyData"
      :min-item-size="minItemSize"
      :buffer="buffer"
      key-field="ownerId"
    >
      <template v-slot="{ item, index, active }">
        <dynamic-scroller-item
          :item="item"
          :active="active"
          :size-dependencies="[item.rowItems]"
          :data-index="index"
        >
          <owners-list-table-body-row
            :bulkSendMailModeFlg="bulkSendMailModeFlg"
            :downloadCsvModeFlg="downloadCsvModeFlg"
            v-bind="item"
            @click.native="selectRow(item.ownerId)"
            @check-target-owner-flg="checkTargetOwnerFlg($event, item.ownerId)"
          />
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import OwnersListTableHeaderRow from '@/components/parts/molecules/OwnersListTableHeaderRow'
import OwnersListTableBodyRow from '@/components/parts/molecules/OwnersListTableBodyRow'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'

export default {
  name: 'OwnersListTable',

  components: {
    BaseSelectBox,
    OwnersListTableHeaderRow,
    OwnersListTableBodyRow,
    DynamicScroller,
    DynamicScrollerItem
  },

  props: {
    sortType: { type: Number, default: 0 },
    orderType: { type: Number, default: 0 },
    sortTypes: {
      type: Array,
      validator: function(arr) {
        return arr.every(v => typeof v === 'string')
      }
    },
    sortBoxStyle: {
      type: Object,
      default: () => ({ maxWidth: '300px' })
    },
    orderBoxStyle: { type: Object },
    headerData: { type: Array },
    bodyData: { type: Array },
    headerStyles: {
      type: Array,
      default: function() {
        return []
      }
    },
    bodyStyles: {
      type: Array,
      default: function() {
        return []
      }
    },
    bulkSendMailModeFlg: { type: Boolean, default: false },
    downloadCsvModeFlg: { type: Boolean, default: false },
    bulkCheckFlg: { type: Boolean, default: false },
    searchCount: { type: Number }
  },

  data() {
    return {
      orderTypesSelectData: [
        { id: 0, name: '昇順' },
        { id: 1, name: '降順' }
      ],
      minItemSize: 60,
      buffer: 10
    }
  },

  computed: {
    sortTypeSelectData() {
      return this.sortTypes.slice().map((v, i) => {
        return { id: i, name: v }
      })
    },
    listTableHeaderData() {
      return {
        rowItems: this.headerData.map((item, i) => {
          return {
            itemId: i + 1,
            itemTexts: item,
            itemStyle: this.headerStyles[i]
          }
        })
      }
    },
    listTableBodyData() {
      return this.bodyData.map((row, i, arr) => {
        let rowItems
        if (this.bulkSendMailModeFlg || this.downloadCsvModeFlg) {
          rowItems = [
            { itemId: 1, itemTexts: [], itemStyle: this.bodyStyles[0] }
          ]
          row.rowItems.forEach((item, j) => {
            rowItems.push({
              itemId: j + 2,
              itemTexts: item,
              itemStyle: this.bodyStyles[j + 1]
            })
          })
        } else {
          rowItems = row.rowItems.map((item, j) => {
            return {
              itemId: j + 1,
              itemTexts: item,
              itemStyle: this.bodyStyles[j]
            }
          })
        }
        return {
          ownerTags: row.ownerTags,
          ownerId: row.id,
          ownerEmail: row.email,
          targetOwnerFlg: this.bulkSendMailModeFlg
            ? row.sendMailFlg
            : row.downloadCsvFlg,
          rowItems,
          evenFlg: (i + 1) % 2 === 0,
          lastFlg: i + 1 === arr.length
        }
      })
    }
  },

  methods: {
    changeSortType(id) {
      this.$emit('sort', id)
    },
    changeOrderType(id) {
      this.$emit('order', id)
    },
    selectRow(ownerId) {
      if (this.bulkSendMailModeFlg || this.downloadCsvModeFlg) return
      this.$emit('click', ownerId)
    },
    bulkCheckTargetOwnerFlg(bulkCheckFlg) {
      this.$emit('bulk-check-target-owner-flg', bulkCheckFlg)
    },
    checkTargetOwnerFlg(targetOwnerFlg, ownerId) {
      this.$emit('check-target-owner-flg', targetOwnerFlg, ownerId)
    }
  }
}
</script>

<style lang="scss" scoped>
.owners-list-table {
  > .table-number-sort {
    display: flex;
    align-items: center;
    height: 64px;
    padding-left: 20px;
    background-color: #{$white};
    border: solid #{$light-grey};
    border-width: 1px 1px 1px 1px;
    box-sizing: border-box;
    > .table-number {
      font-size: 15px;
    }
    > .table-sort {
      display: flex;
      margin: 0 0 0 auto;
      > p {
        font-size: 13px;
        margin: 21px 0;
      }
      > .sort-box {
        margin: 15px 20px 16px 10px;
      }
      > .order-box {
        margin: 15px 20px 16px 0;
      }
    }
  }
  > .table-header {
    border: solid #{$light-grey};
    border-width: 0px 1px 1px 1px;
    box-sizing: border-box;
  }
  > .table-body {
    max-height: calc(100% - 104px);
    border: solid #{$light-grey};
    border-width: 0px 1px 1px 1px;
    box-sizing: border-box;
  }
}
</style>
