<template>
  <div class="owners">
    <div class="wrap">
      <div class="title">飼主・患者</div>
      <div class="contents">
        <div class="button-area">
          <base-button-small-white
            v-if="lookOnlyFlg === 0 && bulkSendMailModeFlg"
            class="button"
            @click="cancelBulkSendMailMode"
            >キャンセル</base-button-small-white
          >
          <base-button-small-white
            v-if="lookOnlyFlg === 0 && downloadCsvModeFlg"
            class="button"
            @click="cancelDownloadCsvMode"
            >キャンセル</base-button-small-white
          >
          <base-button-register
            v-if="lookOnlyFlg === 0 && bulkSendMailModeFlg"
            class="button"
            :text="'メール作成'"
            :styles="{ width: '175px' }"
            :disabled="displayOwners.filter(v => v.sendMailFlg).length === 0"
            @click="mailPopupFlg = true"
          ></base-button-register>
          <base-button-register
            v-if="lookOnlyFlg === 0 && downloadCsvModeFlg"
            class="button"
            :text="'ダウンロード'"
            :styles="{ width: '175px' }"
            :disabled="displayOwners.filter(v => v.downloadCsvFlg).length === 0"
            @click="download"
          ></base-button-register>
          <base-button-register
            v-if="
              lookOnlyFlg === 0 && !bulkSendMailModeFlg && !downloadCsvModeFlg
            "
            class="button"
            :text="'CSV出力'"
            @click="setDownloadCsvMode"
          />
          <base-button-register
            v-if="
              lookOnlyFlg === 0 && !bulkSendMailModeFlg && !downloadCsvModeFlg
            "
            class="button"
            :text="'飼主一括メール送信'"
            @click="setBulkSendMailMode"
          />
          <base-button-register
            v-if="
              lookOnlyFlg === 0 && !bulkSendMailModeFlg && !downloadCsvModeFlg
            "
            class="button"
            :text="'飼主登録'"
            @click="$router.push({ path: `/main/owners/new` })"
          />
        </div>
        <div class="search-area">
          <div class="heading">飼主・患者検索</div>
          <div class="search">
            <search-area
              v-if="isSimpleSearch"
              :textBoxLabel="'検索単語'"
              :textValue="searchWord"
              :placeholder="
                '飼主ID、飼主名、患者ID、患者名、電話番号、住所、タグ'
              "
              :placeholderSmallFont="true"
              :textBoxStyles="{ width: '340px' }"
              :selectBoxLabel="'飼主ID'"
              :selectValue="searchOwnerTypeId"
              :selectBoxData="searchOwnerTypes"
              :toggleDetailSearchFlg="true"
              :searchButtonFlg="true"
              @input="inputSearchWord"
              @select="selectSearchOwnerType"
              @show-detail="toggleSearchAreaType"
              @search="search"
            />
            <search-area-detail
              v-else
              :selectBoxData="detailSelectBoxData"
              :searchConditions="searchConditions"
              :searchButtonFlg="true"
              @hide-detail="toggleSearchAreaType"
              @select="setSelectId"
              @input-text="inputText(1, $event)"
              @input-text2="inputText(2, $event)"
              @add-search-condition="addSearchCondition"
              @trash="deleteSearchCondition"
              @search="search"
              @select-tag="selectTag"
            />
          </div>
        </div>
        <div class="list-area">
          <div class="heading">飼主・患者一覧</div>
          <div class="list">
            <owners-list-table
              :sortType="sortType"
              :orderType="orderType"
              :sortTypes="sortTypes"
              :headerData="headers"
              :bodyData="displayOwners"
              :headerStyles="itemStyles"
              :bodyStyles="itemStyles"
              :bulkSendMailModeFlg="bulkSendMailModeFlg"
              :downloadCsvModeFlg="downloadCsvModeFlg"
              :bulkCheckFlg="bulkCheckFlg"
              :searchCount="searchCount"
              @bulk-check-target-owner-flg="bulkCheckTargetOwnerFlg"
              @check-target-owner-flg="checkTargetOwnerFlg"
              @click="openShowPage"
              @sort="changeSort"
              @order="changeOrder"
            />
          </div>
        </div>
      </div>
    </div>
    <mail-popup
      v-if="mailPopupFlg"
      :owners="displayOwners.filter(v => v.sendMailFlg)"
      @close="mailPopupFlg = false"
    />
    <unsaved-leave-popup />
  </div>
</template>

<script>
import SearchArea from '@/components/parts/molecules/SearchArea'
import SearchAreaDetail from '@/components/parts/molecules/SearchAreaDetail'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import OwnersListTable from '@/components/parts/organisms/OwnersListTable.vue'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import MailPopup from '@/components/popups/MailPopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { kataToHira, hiraToKata, toHanKakuId } from '@/utils/convert_string'
import { compareShowId } from '@/utils/sort_show_id'
import { downloadCsv } from '@/utils/csv'
import moment from 'moment'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'

export default {
  name: 'Owners',

  components: {
    SearchArea,
    SearchAreaDetail,
    BaseButtonRegister,
    OwnersListTable,
    BaseButtonSmallWhite,
    MailPopup,
    UnsavedLeavePopup
  },

  mixins: [DetectWindowSize],

  data() {
    const detailSelectBoxData = [
      { id: 1, name: '飼主名', type: 'textBox' },
      { id: 2, name: '患者名', type: 'textBox' },
      { id: 3, name: '飼主ID', type: 'textBox' },
      { id: 4, name: '患者ID', type: 'textBox' },
      { id: 5, name: '住所', type: 'textBox' },
      { id: 6, name: '予約日', type: 'datePicker' },
      { id: 7, name: 'タグ', type: 'tagsSelect' },
      { id: 8, name: '飼主IDの有無', type: 'radioButton' },
      { id: 9, name: '飼主の性別', type: 'radioButton' },
      { id: 10, name: 'メールアドレスの有無', type: 'radioButton' },
      { id: 11, name: 'メールアドレス', type: 'textBox' },
      { id: 12, name: '電話番号', type: 'textBox' },
      { id: 13, name: '患者の性別', type: 'selectBox' },
      { id: 14, name: '患者の種別', type: 'selectBox' },
      { id: 15, name: '患者の品種', type: 'textBox' },
      { id: 16, name: '患者の毛色', type: 'textBox' },
      { id: 17, name: '患者の年齢', type: 'textBoxes' },
      { id: 18, name: '患者の主治医', type: 'selectBox' },
      { id: 19, name: '次回予約の有無', type: 'radioButton' },
      { id: 20, name: '死亡患者を含む', type: 'radioButton' },
      { id: 21, name: '保険', type: 'oneDatePickerSelectBox' },
      { id: 22, name: 'MC装着日', type: 'datePicker' },
      { id: 23, name: 'MC識別番号', type: 'textBox' }
      // id:24は検索条件：言語での検索で使用しているので使用しないで下さい
    ]
    if (
      this.$store.getters['reservationSetting/getData'].englishOptionFlg === 1
    ) {
      // 2023/10/23のレビュー時、詳細検索は現状の実装だとidで管理していて新しい検索条件を間に入れるとidの順番がバラバラになり
      // 不具合につながる可能性があるため、言語はセレクトボックスの選択肢の一番下に配置されるような仕様にしている。
      // 詳細検索の選択肢の部分はidで識別せずorderの値を別で用意する方法等でいずれ改善する
      detailSelectBoxData.push({ id: 24, name: '言語', type: 'selectBox' })
    }
    return {
      displayOwners: [],
      searchWord: '',
      searchOwnerTypeId: 0,
      searchOwnerTypes: [
        { id: 0, name: '全て' },
        { id: 1, name: '有り' }, // show_id がある owner
        { id: 2, name: '無し' } // show_id がない owner
      ],
      sortType: 0,
      orderType: 0,
      sortTypes: ['飼主ID順', '飼主名順', '住所順'],
      headers: [
        ['飼主ID', '飼主名'],
        ['患者ID', '患者名（種別）'],
        ['電話番号'],
        ['住所']
      ],
      itemStyles: [
        { width: '20%', minWidth: '250px' },
        { width: '25%' },
        { width: '20%', maxWidth: '200px' },
        { width: '35%', textAlign: 'left' }
      ],
      bulkSendMailModeFlg: false,
      downloadCsvModeFlg: false,
      bulkCheckFlg: true,
      isSimpleSearch: true,
      detailSelectBoxData,
      searchConditions: [{ id: 1, selectId: 0, text: '', text2: '' }],
      mailPopupFlg: false,
      searchCount: 0
    }
  },

  computed: {
    ...mapGetters({
      owners: 'owners/getData',
      patients: 'patients/getData',
      getOwnerPatients: 'patients/getDataByOwnerId',
      getSpecies: 'species/getDataById',
      species: 'species/getData',
      getMasterDatum: 'master/getDataById',
      prefectures: 'master/getPrefectures',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      reservations: 'reservations/getData',
      ownerTags: 'ownerTags/getData',
      getOwnerTag: 'ownerTags/getDataById',
      getOwnerReservations: 'reservations/getOwnerReservations'
    })
  },

  watch: {
    owners: function() {
      this.displayOwners = this.makeDisplayOwners()
    },
    patients: function() {
      this.displayOwners = this.makeDisplayOwners()
    },
    ownerTags: function() {
      this.displayOwners = this.makeDisplayOwners()
    },
    sortType: function() {
      this.displayOwners = this.makeDisplayOwners()
    },
    orderType: function() {
      this.displayOwners = this.makeDisplayOwners()
    }
  },

  created() {
    if (this.$route.params.text) {
      this.searchWord = this.$route.params.text
    }
    this.displayOwners = this.makeDisplayOwners()
  },

  methods: {
    search() {
      this.displayOwners = this.makeDisplayOwners()
      this.searchCount++
    },
    makeDisplayOwners() {
      const orderedOwners = this.makeOrderedOwners()
      return orderedOwners.map(owner => {
        const searchTerm = this.searchWord.replace(/\s+/g, '')
        const patients =
          this.isSimpleSearch &&
          this.isSimpleSearchTargetOwner(owner, searchTerm)
            ? owner.patientsFilteredByDeathFlg
            : owner.patientsFilteredByAllCondition
        const rowItems = [
          this.makeOwnerShowIdFullName(owner),
          this.makePatientsShowIdName(patients),
          this.makeOwnerTel(owner),
          this.makeOwnerFullAddress(owner)
        ]
        const ownerTags = this.makeOwnerTags(owner)
        return {
          id: owner.id,
          email: owner.email,
          sendMailFlg: owner.sendMailFlg,
          downloadCsvFlg: owner.downloadCsvFlg,
          ownerTags,
          rowItems,
          patients,
          postalCode: owner.postalCode,
          // ↓ MailPopup用
          lastName: owner.lastName,
          firstName: owner.firstName
        }
      })
    },
    makeOrderedOwners() {
      const sortedOwners = this.makeSortedOwners().slice()
      return this.orderType === 1 ? sortedOwners.reverse() : sortedOwners
    },
    makeSortedOwners() {
      const list = this.makeFilteredOwners().slice()
      switch (this.sortType) {
        case 1: // 飼主名順
          return list.sort((a, b) => {
            if (a.lastNameKana === b.lastNameKana) {
              if (a.lastName === b.lastName) {
                if (a.firstNameKana === b.firstNameKana) {
                  return a.firstName < b.firstName ? -1 : 1
                } else {
                  return a.firstNameKana < b.firstNameKana ? -1 : 1
                }
              } else {
                return a.lastName < b.lastName ? -1 : 1
              }
            } else {
              return a.lastNameKana < b.lastNameKana ? -1 : 1
            }
          })
        case 2: // 住所順
          return list.sort((a, b) => {
            if (
              this.prefectures.some(p => p.id === a.prefectureId) &&
              !this.prefectures.some(p => p.id === b.prefectureId)
            ) {
              return -1
            } else if (
              !this.prefectures.some(p => p.id === a.prefectureId) &&
              this.prefectures.some(p => p.id === b.prefectureId)
            ) {
              return 1
            } else {
              if (a.prefectureId === b.prefectureId) {
                if (a.address === b.address) {
                  if (a.building === b.building) {
                    return compareShowId(a.showId, b.showId)
                  } else {
                    return a.building < b.building ? -1 : 1
                  }
                } else {
                  return a.address < b.address ? -1 : 1
                }
              } else {
                return a.prefectureId < b.prefectureId ? -1 : 1
              }
            }
          })
        default:
          // 飼主ID順
          return list.sort((a, b) => compareShowId(a.showId, b.showId))
      }
    },
    isSimpleSearchTargetOwner(owner, searchTerm) {
      return (
        owner.showId.includes(searchTerm) ||
        owner.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        owner.fullNameKana.includes(searchTerm) ||
        kataToHira(owner.fullNameKana).includes(searchTerm) ||
        owner.tel.includes(searchTerm.replace(/-/g, '')) ||
        owner.noNumFullAddress.includes(searchTerm) ||
        owner.ownerTags.some(
          t =>
            t.toLowerCase().includes(searchTerm.toLowerCase()) ||
            hiraToKata(t).includes(searchTerm) ||
            kataToHira(t).includes(searchTerm)
        )
      )
    },
    isSimpleSearchTargetPatient(searchTerm, owner, patient) {
      return (
        patient.showId.includes(searchTerm) ||
        patient.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.nameKana.includes(searchTerm) ||
        kataToHira(patient.nameKana).includes(searchTerm) ||
        this.matchWithOwnerPatientName(searchTerm, owner, patient)
      )
    },
    makeFilteredOwners() {
      if (this.isSimpleSearch) {
        const searchTerm = this.searchWord.replace(/\s+/g, '')
        const ownersForSearch = this.makeOwnersForSearch()
        return ownersForSearch.filter(o => {
          const isSearchTarget =
            this.searchOwnerTypeId === 1
              ? o.showId
              : this.searchOwnerTypeId === 2
              ? !o.showId
              : true
          return (
            isSearchTarget &&
            (this.isSimpleSearchTargetOwner(o, searchTerm) ||
              o.patientsFilteredByAllCondition.some(p =>
                this.isSimpleSearchTargetPatient(searchTerm, o, p)
              ))
          )
        })
      } else {
        let filteredOwners = this.makeOwnersForSearch()
        this.searchConditions.forEach(sc => {
          const searchTerm =
            typeof sc.text == 'string' ? sc.text.replace(/\s+/g, '') : sc.text
          switch (sc.selectId) {
            case 1: //飼主名
              filteredOwners = filteredOwners.filter(
                v =>
                  v.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  v.fullNameKana.includes(searchTerm) ||
                  kataToHira(v.fullNameKana).includes(searchTerm)
              )
              break
            // 下記の条件はmakeOwnersForSearchで処理
            // case  2「患者名」
            // case  4「患者ID」
            // case 13「患者の性別」
            // case 14「患者の種別」
            // case 15「患者の品種」
            // case 16「患者の毛色」
            // case 17「患者の年齢」
            // case 18「患者の主治医」
            // case 20「死亡患者を含む」
            // case 21「患者の保険」
            // case 23「MC識別番号」
            case 3: //飼主ID
              filteredOwners = filteredOwners.filter(v =>
                v.showId.includes(toHanKakuId(searchTerm))
              )
              break
            case 5: //住所
              filteredOwners = filteredOwners.filter(v =>
                v.fullAddress.includes(searchTerm)
              )
              break
            case 6: {
              //予約日
              const start = moment(sc.text.substring(0, 8), 'YYYYMMDD')
              const end = moment(sc.text.substring(9, 17), 'YYYYMMDD')
              const targetOwnerIds = this.reservations
                .filter(
                  v =>
                    moment(v.date, 'YYYYMMDD').isSameOrAfter(start) &&
                    moment(v.date, 'YYYYMMDD').isSameOrBefore(end)
                )
                .map(v => v.ownerId)
              filteredOwners = filteredOwners.filter(v =>
                targetOwnerIds.includes(v.id)
              )
              break
            }
            case 7: //タグ
              filteredOwners =
                sc.text.length === 0
                  ? filteredOwners
                  : filteredOwners.filter(item =>
                      sc.text.every(tag => item.ownerTagIds.includes(tag))
                    )
              break
            case 8: //飼主IDの有無
              filteredOwners =
                searchTerm === '有り'
                  ? (filteredOwners = filteredOwners.filter(
                      v => v.showId !== ''
                    ))
                  : (filteredOwners = filteredOwners.filter(
                      v => v.showId === ''
                    ))
              break
            case 9: //飼主の性別
              filteredOwners =
                searchTerm === '男性'
                  ? (filteredOwners = filteredOwners.filter(v => v.sex === 1))
                  : searchTerm === '女性'
                  ? (filteredOwners = filteredOwners.filter(v => v.sex === 2))
                  : searchTerm === 'その他'
                  ? (filteredOwners = filteredOwners.filter(v => v.sex === 3))
                  : (filteredOwners = filteredOwners.filter(v => v.sex === 0))
              break
            case 10: //メールアドレスの有無
              filteredOwners =
                searchTerm === '有り'
                  ? (filteredOwners = filteredOwners.filter(
                      v => v.email !== ''
                    ))
                  : (filteredOwners = filteredOwners.filter(
                      v => v.email === ''
                    ))
              break
            case 11: //メールアドレス
              if (searchTerm === '') break
              filteredOwners = filteredOwners.filter(v =>
                v.email.toLowerCase().includes(searchTerm.toLowerCase())
              )
              break
            case 12: //電話番号
              filteredOwners = filteredOwners.filter(v =>
                v.tel.includes(searchTerm.replace(/-/g, ''))
              )
              break
            case 19: {
              //次回予約の有無
              const isNextReservation = owner => {
                const reservations = this.getOwnerReservations(owner.id)
                return reservations.some(r => {
                  return moment(r.date + r.startTime, 'YYYYMMDDHHmm').isAfter(
                    moment()
                  )
                })
              }
              filteredOwners =
                searchTerm === '有り'
                  ? filteredOwners.filter(owner => isNextReservation(owner))
                  : filteredOwners.filter(owner => !isNextReservation(owner))
              break
            }
            case 22: //MC装着日
              {
                const [start, end] = sc.text
                  .split(',')
                  .map(v => moment(v, 'YYYYMMDD'))
                const targetOwnerIds = this.patients.flatMap(v => {
                  const matchingDate =
                    moment(v.mcDate, 'YYYYMMDD').isSameOrAfter(start) &&
                    moment(v.mcDate, 'YYYYMMDD').isSameOrBefore(end)
                  return matchingDate ? v.ownerId : []
                })
                filteredOwners = filteredOwners.filter(v =>
                  targetOwnerIds.includes(v.id)
                )
              }
              break
            case 24: //言語
              filteredOwners = filteredOwners.filter(
                v => v.englishFlg === searchTerm
              )
              break
          }
        })
        return filteredOwners
      }
    },
    matchWithOwnerPatientName(searchTerm, owner, patient) {
      const ownerLastNames = [
        owner.lastName,
        owner.lastNameKana,
        kataToHira(owner.lastNameKana)
      ]
      const patientNames = [
        patient.name,
        patient.nameKana,
        kataToHira(patient.nameKana)
      ]
      let match = false
      outerBlock: {
        for (const ownerLastName of ownerLastNames) {
          for (const patientName of patientNames) {
            match = `${ownerLastName}${patientName}`
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
            if (match) break outerBlock
          }
        }
      }
      return match
    },
    makeFilterdPatients(owner) {
      let patients = this.getOwnerPatients(owner.id) || []
      let patientsFilteredByDeathFlg = []
      let patientSearchFlg = false
      if (this.isSimpleSearch) {
        // 簡易検索 -> 生存患者を表示
        patients = patients.filter(patient => !patient.deathFlg)
        patientsFilteredByDeathFlg = patients.slice()
        const searchTerm = this.searchWord.replace(/\s+/g, '')
        patients = patients.filter(p =>
          this.isSimpleSearchTargetPatient(searchTerm, owner, p)
        )
      } else {
        // 詳細検索 -> 死亡患者のみ 生存患者のみ では対象の患者と飼主を表示
        const deathSearchCondition =
          this.searchConditions.find(v => v.selectId === 20)?.text || ''
        if (deathSearchCondition === '死亡患者のみ') {
          patients = patients.filter(patient => patient.deathFlg)
          patientSearchFlg = true
        } else if (deathSearchCondition === '生存患者のみ') {
          patients = patients.filter(patient => !patient.deathFlg)
          patientSearchFlg = true
        } else if (!deathSearchCondition) {
          patients = patients.filter(patient => !patient.deathFlg)
        }
        patientsFilteredByDeathFlg = patients.slice()
        this.searchConditions.forEach(sc => {
          const searchTerm =
            typeof sc.text == 'string' ? sc.text.replace(/\s+/g, '') : sc.text
          const searchTerm2 = sc.text2
          switch (sc.selectId) {
            case 2: //患者名
              if (searchTerm === '') break
              patients = patients.filter(
                p =>
                  p.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  p.nameKana.includes(searchTerm) ||
                  kataToHira(p.nameKana).includes(searchTerm)
              )
              patientSearchFlg = true
              break
            case 4: //患者ID
              if (searchTerm === '') break
              patients = patients.filter(p =>
                p.showId.includes(toHanKakuId(searchTerm))
              )
              patientSearchFlg = true
              break
            case 13: //患者の性別
              patients = patients.filter(p => p.sex === searchTerm)
              patientSearchFlg = true
              break
            case 14: //患者の種別
              patients = patients.filter(p => p.speciesId === searchTerm)
              patientSearchFlg = true
              break
            case 15: //患者の品種
              if (searchTerm === '') break
              patients = patients.filter(p => {
                const kataBreed = hiraToKata(p.breed)
                const kataSearchTerm = hiraToKata(searchTerm)
                return kataBreed.includes(kataSearchTerm)
              })
              patientSearchFlg = true
              break
            case 16: //患者の毛色
              if (searchTerm === '') break
              patients = patients.filter(p => p.hairColor.includes(searchTerm))
              patientSearchFlg = true
              break
            case 17: {
              //患者の年齢
              if (searchTerm === '' && searchTerm2 === '') break
              const youngAge = Number(searchTerm)
              const oldAge = Number(searchTerm2)
              patients = patients.filter(p => {
                const latestDate = p.deathDate ? moment(p.deathDate) : moment()
                let age
                if (p.birthday.length === 8 || p.birthday.length === 6) {
                  age =
                    latestDate.diff(moment(p.birthday), 'years') > 0
                      ? latestDate.diff(moment(p.birthday), 'years')
                      : 0
                } else if (p.birthday.length === 4) {
                  age = latestDate.year() - moment(p.birthday).year()
                }
                if (
                  youngAge === 0 &&
                  oldAge === 0 &&
                  searchTerm === '0' &&
                  searchTerm2 === '0'
                ) {
                  return age === 0 ? true : false
                } else if (!oldAge) {
                  return youngAge <= age ? true : false
                } else if (oldAge) {
                  return youngAge <= age && age <= oldAge ? true : false
                }
              })
              patientSearchFlg = true
              break
            }
            case 18: //患者の主治医
              patients = patients.filter(p => p.staffId === searchTerm)
              patientSearchFlg = true
              break
            case 21: //患者の保険
              patients = patients.filter(p =>
                this.isHitByPatientInsurance(p, searchTerm, searchTerm2)
              )
              patientSearchFlg = true
              break
            case 23: //MC識別番号
              patients = patients.filter(p => p.mcCode.includes(searchTerm))
              patientSearchFlg = true
              break
          }
        })
      }
      return {
        patientsFilteredByAllCondition: patients,
        patientsFilteredByDeathFlg,
        patientSearchFlg
      }
    },
    makeOwnersForSearch() {
      const originalOwners = this.owners.flatMap(owner => {
        const {
          patientsFilteredByAllCondition,
          patientsFilteredByDeathFlg,
          patientSearchFlg
        } = this.makeFilterdPatients(owner)
        if (patientSearchFlg && !patientsFilteredByAllCondition.length) {
          return []
        }
        const prefectureName =
          this.getMasterDatum('prefectures', owner.prefectureId)?.name || ''
        const fullAddress = prefectureName + owner.address + owner.building
        return {
          ...owner,
          sendMailFlg: owner.email ? this.bulkCheckFlg : false,
          downloadCsvFlg: this.bulkCheckFlg,
          fullName: owner.lastName + owner.firstName,
          fullNameKana: owner.lastNameKana + owner.firstNameKana,
          fullAddress,
          noNumFullAddress: fullAddress.replace(/[0-9０-９]/g, ''),
          patientsFilteredByAllCondition,
          patientsFilteredByDeathFlg,
          ownerTags: owner.ownerTagIds.map(id => {
            const ownerTag = this.getOwnerTag(id)
            return ownerTag ? ownerTag.name : ''
          })
        }
      })
      return originalOwners
    },
    makeOwnerShowIdFullName(owner) {
      return [owner.showId, owner.lastName + ' ' + owner.firstName]
    },
    makeOwnerTel(owner) {
      return [owner.tel]
    },
    makeOwnerTags(owner) {
      return this.ownerTags.filter(v => owner.ownerTagIds.includes(v.id))
    },
    makePatientsShowIdName(patients) {
      return _.flattenDeep(
        patients.map(patient => {
          const speciesName = this.getSpecies(patient.speciesId)
            ? `（${this.getSpecies(patient.speciesId).name}）`
            : ''
          return [patient.showId, patient.name + speciesName]
        })
      )
    },
    makeOwnerFullAddress(owner) {
      const prefName =
        this.getMasterDatum('prefectures', owner.prefectureId)?.name || ''
      return [prefName + owner.address, owner.building].filter(v => v !== '')
    },
    isPeriodUnsetAnicomPatient(anicomPatient) {
      return anicomPatient.startDate === '' && anicomPatient.endDate === ''
    },
    isWithinPeriod(selectDate, startDate, endDate) {
      return startDate <= selectDate && selectDate <= endDate
    },
    isHitAnicomPatient(patient, selectDate) {
      if (patient.insurance !== 'anicom') return false
      const anicomPatient = this.$store.getters[
        'anicomPatients/getAnicomPatient'
      ](patient.id)
      return Boolean(
        anicomPatient &&
          (this.isPeriodUnsetAnicomPatient(anicomPatient) ||
            this.isWithinPeriod(
              selectDate,
              anicomPatient.startDate,
              anicomPatient.endDate
            ))
      )
    },
    isHitIpetPatient(patient, selectDate) {
      if (patient.insurance !== 'ipet' && patient.insurance !== 'docomo') {
        return false
      }
      const ipetPatient = this.$store.getters['ipetPatients/getIpetPatient'](
        patient.id
      )
      return Boolean(
        ipetPatient &&
          this.isWithinPeriod(
            selectDate,
            ipetPatient.startDate,
            ipetPatient.endDate
          )
      )
    },
    isHitByPatientInsurance(patient, selectInsurance, selectDate) {
      if (selectInsurance === 0) {
        return this.isHitAnicomPatient(patient, selectDate)
      } else if (selectInsurance === 1) {
        return this.isHitIpetPatient(patient, selectDate)
      } else {
        // selectInsurance === 2
        if (patient.insurance === '') {
          return true
        } else if (patient.insurance === 'anicom') {
          return !this.isHitAnicomPatient(patient, selectDate)
        } else if (
          patient.insurance === 'ipet' ||
          patient.insurance === 'docomo'
        ) {
          return !this.isHitIpetPatient(patient, selectDate)
        }
      }
    },
    inputSearchWord(text) {
      this.searchWord = text
    },
    selectSearchOwnerType(id) {
      this.searchOwnerTypeId = id
    },
    changeSort(id) {
      this.sortType = id
    },
    changeOrder(id) {
      this.orderType = id
    },
    openShowPage(ownerId) {
      this.$router.push({ path: `owners/${ownerId}` })
    },
    toggleSearchAreaType() {
      this.searchWord = ''
      this.searchOwnerTypeId = 0
      this.searchConditions = [{ id: 1, selectId: 0, text: '', text2: '' }]
      this.isSimpleSearch = !this.isSimpleSearch
    },
    setSelectId(idIndex) {
      const targetSearchCondition = this.searchConditions[idIndex.index]
      targetSearchCondition.selectId = idIndex.id
      if (idIndex.id === 8 || idIndex.id === 10 || idIndex.id === 19) {
        targetSearchCondition.text = '有り'
      } else if (idIndex.id === 9) {
        targetSearchCondition.text = '未設定'
      } else if (idIndex.id === 13 || idIndex.id === 18 || idIndex.id === 24) {
        targetSearchCondition.text = 0
      } else if (idIndex.id === 14) {
        const speciesId = this.species[0].id
        targetSearchCondition.text = speciesId
      } else if (idIndex.id === 20) {
        targetSearchCondition.text = '全て'
      } else if (idIndex.id === 21) {
        targetSearchCondition.text = 0
      } else {
        targetSearchCondition.text = ''
      }
      targetSearchCondition.text2 = ''
      this.$set(this.searchConditions, idIndex.index, targetSearchCondition)
    },
    setText(num, textObj) {
      const text = num === 1 ? 'text' : `text${num}`
      const targetSearchCondition = this.searchConditions[textObj.index]
      targetSearchCondition[text] = textObj.text
      this.$set(this.searchConditions, textObj.index, targetSearchCondition)
    },
    inputText(num, textObj) {
      this.setText(num, textObj)
    },
    addSearchCondition() {
      const id = this.searchConditions[this.searchConditions.length - 1].id + 1
      this.searchConditions.push({ id, selectId: 0, text: '', text2: '' })
    },
    deleteSearchCondition(id) {
      this.searchConditions = this.searchConditions.filter(v => v.id !== id)
    },
    setBulkSendMailMode() {
      this.bulkSendMailModeFlg = true
      this.headers.unshift([''])
      this.itemStyles.unshift({ width: '10%', maxWidth: '100px' })
      this.itemStyles[4].width = '30%'
      this.bulkCheckTargetOwnerFlg(true)
    },
    setDownloadCsvMode() {
      this.downloadCsvModeFlg = true
      this.headers.unshift([''])
      this.itemStyles.unshift({ width: '10%', maxWidth: '100px' })
      this.itemStyles[4].width = '30%'
      this.bulkCheckTargetOwnerFlg(true)
    },
    cancelBulkSendMailMode() {
      this.bulkSendMailModeFlg = false
      this.headers.shift()
      this.itemStyles.shift()
      this.itemStyles[3].width = '35%'
    },
    cancelDownloadCsvMode() {
      this.downloadCsvModeFlg = false
      this.headers.shift()
      this.itemStyles.shift()
      this.itemStyles[3].width = '35%'
    },
    checkTargetOwnerFlg(targetOwnerFlg, ownerId) {
      const index = this.displayOwners.findIndex(v => v.id === ownerId)
      if (index !== -1) {
        if (this.bulkSendMailModeFlg) {
          this.displayOwners[index].sendMailFlg = targetOwnerFlg
        } else {
          this.displayOwners[index].downloadCsvFlg = targetOwnerFlg
        }
        if (targetOwnerFlg === false) this.bulkCheckFlg = false
      }
    },
    bulkCheckTargetOwnerFlg(bulkCheckFlg) {
      this.bulkCheckFlg = bulkCheckFlg
      if (this.bulkSendMailModeFlg) {
        this.displayOwners = this.displayOwners.map(v => {
          if (v.email !== '') {
            v.sendMailFlg = this.bulkCheckFlg ? true : false
          }
          return v
        })
      } else {
        this.displayOwners = this.displayOwners.map(v => {
          v.downloadCsvFlg = this.bulkCheckFlg ? true : false
          return v
        })
      }
    },
    createCsv() {
      const targetOwners = this.displayOwners.filter(v => v.downloadCsvFlg)
      const headerStr =
        '飼主ID,飼主名,患者ID,患者名,種別,電話番号,郵便番号,住所\n'
      let bodyStr = ''
      const makeRowStr = (owner, patient = null) => [
        owner.rowItems[0][0],
        owner.rowItems[0][1],
        patient ? patient.showId : '',
        patient ? patient.name : '',
        patient && this.getSpecies(patient.speciesId)
          ? this.getSpecies(patient.speciesId).name
          : '',
        owner.rowItems[2][0] ?? '',
        owner.postalCode,
        (owner.rowItems[3][0] ?? '') + (owner.rowItems[3][1] ?? '')
      ]
      for (const owner of targetOwners) {
        const patients = owner.patients
        if (patients.length > 0) {
          for (const patient of patients) {
            bodyStr += makeRowStr(owner, patient).join() + '\n'
          }
        } else {
          bodyStr += makeRowStr(owner).join() + '\n'
        }
      }
      const csvStr = headerStr + bodyStr
      const fileName = `patients`
      return { csvStr, fileName }
    },
    download() {
      const { csvStr, fileName } = this.createCsv()
      downloadCsv(csvStr, fileName)
    },
    selectTag(tagId, index) {
      const tagsCondition = this.searchConditions[index]
      tagsCondition.text =
        tagsCondition.text === ''
          ? [tagId]
          : tagsCondition.text.includes(tagId)
          ? tagsCondition.text.filter(v => v !== tagId)
          : [...tagsCondition.text, tagId]
      this.$set(this.searchConditions, index, tagsCondition)
    }
  }
}
</script>

<style lang="scss" scoped>
.owners {
  width: 100%;
  text-align: left;
  overflow: auto;
  > .wrap {
    min-width: 720px;
    padding: 24px 48px;
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .contents {
      margin-top: 20px;
      > .button-area {
        display: flex;
        justify-content: flex-end;
        > .button {
          margin-left: 20px;
        }
      }
      > .search-area {
        margin-top: 16px;
        > .heading {
          margin-bottom: 10px;
          font-size: 15px;
          font-weight: bold;
        }
        > .search {
          ::v-deep .search-area {
            min-width: 0px;
          }
        }
      }
      > .list-area {
        margin-top: 20px;
        > .heading {
          margin-bottom: 10px;
          font-size: 15px;
          font-weight: bold;
        }
        > .list {
          ::v-deep .table-body {
            max-height: 610px;
          }
        }
      }
    }
  }
}
</style>
