var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"owners-list-table-body-row",class:{
    'mail-sending-mode': _vm.bulkSendMailModeFlg || _vm.downloadCsvModeFlg,
    even: _vm.evenFlg,
    last: _vm.lastFlg
  },attrs:{"data-e2e":"owners-list-table-body-row"}},_vm._l((_vm.rowItems),function(item){return _c('owners-list-table-row-item',_vm._b({key:item.itemId},'owners-list-table-row-item',item,false),[((_vm.bulkSendMailModeFlg || _vm.downloadCsvModeFlg) && item.itemId === 1)?_c('base-check-box',{attrs:{"isChecked":_vm.targetOwnerFlg,"disabled":_vm.bulkSendMailModeFlg && _vm.ownerEmail === ''},on:{"input":_vm.checkTargetOwnerFlg}}):_vm._e(),(
        ((_vm.bulkSendMailModeFlg || _vm.downloadCsvModeFlg) &&
          item.itemId === 2 &&
          _vm.ownerTags.length > 0) ||
          (!(_vm.bulkSendMailModeFlg || _vm.downloadCsvModeFlg) &&
            item.itemId === 1 &&
            _vm.ownerTags.length > 0)
      )?_c('div',{staticClass:"tags wrap"},_vm._l((_vm.ownerTags),function(tag){return _c('div',{key:tag.id},[_c('base-tag-item',{attrs:{"tag":tag}})],1)}),0):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }